import React from 'react';
import Helmet from "react-helmet";
import styled from 'styled-components';
import Layout from 'components/Layout';
import SEO from '../components/seo';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

const NotFoundDiv = styled.div`
	padding:30px;
`;

const NotFoundPage = () => {
	const intl = useIntl()
	return(
	  <Layout>
	  	<Helmet>
      		
	  	</Helmet>
	    <SEO title="Thank You Page" />
	    <NotFoundDiv>
	    	<h1>Thank You!</h1>
	    	<p>Your message has been sent!</p>
	  	</NotFoundDiv>
	  </Layout>
	);
};

export default NotFoundPage;
